import axios from "axios";
import { getLocalStorageUser } from "../src/utils/helpers";
import * as Sentry from "@sentry/react";

const BASE_URL = import.meta.env.VITE_BACKEND_URL + import.meta.env.VITE_REGION;

export const uploadImage = async (file, eventId) => {
  const formData = new FormData();
  formData.append("file", file);
  const user = getLocalStorageUser();
  formData.append("user", user._id);
  formData.append("event", eventId);
  try {
    const { data } = await axios.post(`${BASE_URL}/api/images`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return data;
  } catch (error) {
    Sentry.withScope(function (scope) {
      scope.setTag("formData", formData);
      scope.setLevel("warning");
      scope.setFingerprint([
        "POST",
        "/api/images",
        String(error?.response?.status),
      ]);
      Sentry.captureException(error);
    });
    throw error;
  }
};
