// ** React Imports
import { useEffect } from "react";

// ** Mui Imports
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";

// ** Third party libraries
import dayjs from "dayjs";
import axios from "axios";
import { useNavigate } from "react-router-dom";

// ** Api Imports
import { getDemoUser } from "../../api/userApi";
import { createNewEvent } from "../../api/eventApi";

const TryDemoInstant = () => {
  const region = import.meta.env.VITE_REGION;
  const backendURL = import.meta.env.VITE_BACKEND_URL;

  const navigate = useNavigate();

  const generateUUID = () => {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c === "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  };

  function generatePin() {
    const pinLength = 6;
    let pin = "";

    for (let i = 0; i < pinLength; i++) {
      // Generate a random number between 0 and 9
      const randomDigit = Math.floor(Math.random() * 10);
      pin += randomDigit.toString();
    }

    return pin;
  }

  const createOrFindDemoEvent = async (demoId, today) => {
    try {
      const { user: adminDemoUser, token } = await getDemoUser();
      const isRomanian = region === "ro";
      const newDemoEventPayload = {
        user: adminDemoUser._id,
        name: isRomanian ? `Demo ${today}` : `Demo Event ${today}`,
        welcomeMessage: isRomanian ? "Bine ați venit!" : "Welcome!",
        showImages: true,
        showMessages: true,
        date: dayjs().format("YYYY-MM-DD"), // Simplified date formatting
        isDemo: true,
        demoId: demoId,
        pin: generatePin(),
      };

      const newDemoEvent = await createNewEvent(newDemoEventPayload, token);

      const saveUser = { ...adminDemoUser, password: "" };
      localStorage.setItem("user", JSON.stringify(saveUser));

      navigate("/media?event=" + newDemoEvent._id, { replace: true });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const genUuid = generateUUID();

    let today = dayjs().startOf("day");

    const existingDemoDate = localStorage.getItem("demoDate");
    if (existingDemoDate != today.format("YYYY-MM-DD")) {
      localStorage.setItem("demoId", genUuid);
      localStorage.setItem("demoDate", today.format("YYYY-MM-DD"));
    }
    const demoId = localStorage.getItem("demoId");
    createOrFindDemoEvent(demoId, today.format("DD.MM.YYYY"));
  }, []);
  return (
    <>
      <CssBaseline />
      <Container>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Container>
    </>
  );
};

export default TryDemoInstant;
